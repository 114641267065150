<script>
  import { timeStamp } from "../js/time"
  import { entry } from "../js/stores"

  // PROPS
  export let log // object
  export let index = 0 // integer
  export let preview = false
  let edit = false
  let key
  let keyCode
  let editDiv
  let tempEdit
  // $: parsed = parseHashTags(log.content, log)
  // let parsed = parseHashTags(log.content)

  // CONTEXT
  import { getContext } from "svelte"
  const func = getContext("func")

  function isOdd(num) {
    let answer = num % 2
    if (answer == 1) return true
    return false
  }

  function editContent() {
    edit = true
    func.modify(this.id)
    tempEdit = $entry
    console.log(tempEdit)
  }

  function submitEdit() {
    // console.log(`you hit enter or tab or lost focus`)
    editDiv.blur()
    if (tempEdit == editDiv.innerHTML) {
      // don't do anything
    } else {
      $entry = editDiv.innerHTML
      func.edit($entry)
    }
    edit = false
    $entry = ""
  }

  function handleKeyDown(event) {
    key = event.key
    keyCode = event.keyCode
    if (keyCode == 13 || keyCode == 9) {
      submitEdit()
    }
  }

  function handleKeyUp(event) {
    // if (!edit) return
    $entry = this.innerHTML
  }

  export function handleHash() {
    console.log(`handleHash`)
  }
</script>

<div class="log-item" id={log.id} class:odd={isOdd(index)}>
  <div class="log-top">
    <div class="log-tc">Created: {timeStamp(log.tc, true)}</div>
    <div class="log-tm">
      {log.tm > log.tc ? `Modified: ${timeStamp(log.tm, true)}` : ``}
    </div>
  </div>
  <div class="log-main">
    <div
      id={log.id}
      bind:this={editDiv}
      class="log-content"
      contenteditable="true"
      on:blur={submitEdit}
      on:keydown={handleKeyDown}
      on:keyup={handleKeyUp}
      on:click={editContent}>
      {log.content}
    </div>
  </div>
  <!-- <div class="log-main-parsed">
    {@html parsed}
  </div> -->
  <div class="log-bottom">
    {#if !preview}
      {#if log.tags}
        <div class="tags">
          {#each log.tags as tag}<span class="tag">{tag}</span>{/each}
        </div>
      {/if}
      <div class="buttons">
        <div class="btn btn-copy" on:click={() => func.copy(log.id)}>Copy</div>
        <div class="btn btn-delete" on:click={() => func.delete(log.id)}>
          Delete
        </div>
      </div>
      <!-- <div class="btn btn-edit" on:click={() => func.modify(log.id)}>Edit</div> -->
    {/if}
  </div>
</div>

<style>
  .log-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: var(--color_bg_2);
    margin: 10px 0;
  }

  .log-item.odd {
    background-color: var(--color_bg_2);
  }
  .log-main {
    padding: 10px;
    display: flex;
    width: 100%;
  }

  .log-top,
  .log-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.8em;
    background-color: var(--color_bg_1);
    padding: 5px 10px;
  }

  .log-item.odd .log-top {
    background-color: var(--color_bg_1_odd);
  }
  .log-content {
    width: 100%;
    height: 100%;
    padding: 5px 0;
    pointer-events: all;
    cursor: text;
  }
  .log-bottom {
    justify-content: space-between;
    background-color: var(--color_bg_1);
  }
  .tags,
  .buttons {
    display: flex;
    flex: 1;
  }
  .tags .tag {
    margin-right: 8px;
    color: var(--color_highlight);
    cursor: pointer;
  }
  .buttons {
    justify-content: flex-end;
  }
  :global(.hash) {
    color: var(--color_highlight);
  }
  .btn {
    cursor: pointer;
    margin-left: 15px;
  }
</style>
