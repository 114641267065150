export const daysOfWeek = [
  { i: 0, day: "Sunday", abr: "Sun", rr: "SU" },
  { i: 1, day: "Monday", abr: "Mon", rr: "MO" },
  { i: 2, day: "Tuesday", abr: "Tue", rr: "TU" },
  { i: 3, day: "Wednesday", abr: "Wed", rr: "WE" },
  { i: 4, day: "Thursday", abr: "Thu", rr: "TH" },
  { i: 5, day: "Friday", abr: "Fri", rr: "FR" },
  { i: 6, day: "Saturday", abr: "Sat", rr: "SA" },
]

export function todaysIndex() {
  let today = new Date()
  return today.getDay();
}

export function getDayIndex( prop, val ) {
  // console.log(`getDayIndex prop:`, prop, `val:`, val)
  let index = daysOfWeek.findIndex( x => x[ prop ] == val )
  return index
}

export function formatTime( time, ampmCharacters ) {
  // ampmCharacters: 0 = am/pm, 1 = a/p 2 = ''
  time = new Date( time )
  let timeOptions = {
    hour: `numeric`,
    minute: `numeric`,
    hour12: true,
    dayPeriod: `narrow`,
    // timeStyle: `short`,
  }
  // let hours = time.getHours
  if ( !ampmCharacters ) ampmCharacters = 0;
  let timeFormat = new Intl.DateTimeFormat( `en-US`, timeOptions )
  let timeString = timeFormat.format( time )
  if ( ampmCharacters == 0 ) {
    timeString = timeString.replace( ' ', '' ).toLowerCase()
  } else {
    timeString = timeString.replace( ' ', '' ).slice( 0, ampmCharacters ).toLowerCase()
  }
  return timeString
}

export function formatTimeSpan( start, end, separator = ' - ', ampmCharacters ) {
  let startTimeOfDay = amOrPm( start )
  let endTimeOfDay = amOrPm( end )
  let spanString;
  if ( startTimeOfDay == endTimeOfDay ) {
    spanString = `${formatTime(start, ampmCharacters)}${separator}${formatTime(end, ampmCharacters)}`
  } else {
    spanString = `${formatTime(start, ampmCharacters)}${separator}${formatTime(end, ampmCharacters)}`
  }
  return spanString
}

export function amOrPm( time ) {
  time = new Date( time )
  let timeHours = time.getHours()
  let timeOfDay = timeHours < 12 ? 'am' : 'pm'
  return timeOfDay
}

export function getNextDayOfTheWeek( dayIndex, excludeToday = false, refDate = new Date() ) {
  // const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
  // .indexOf(dayName.slice(0, 3).toLowerCase());
  if ( dayIndex < 0 ) return;
  refDate.setHours( 0, 0, 0, 0 );
  refDate.setDate( refDate.getDate() + +!!excludeToday +
    ( dayIndex + 7 - refDate.getDay() - +!!excludeToday ) % 7 );
  return refDate;
}

export function getDOW( format, date ) {
  if ( !( date instanceof Date ) ) {
    // console.log(`date isn't a date`)
  }
  if ( !date ) {
    // console.log(`no date provided...assuming today`)
    date = new Date();
    if ( date instanceof Date ) {
      // console.log(`today is a date`)
    }
  }
  if ( !( date instanceof Date ) && typeof date == 'string' ) {
    // console.log(`date [${date}] not instance of date, but it is a string, let's turn it into a date!`)
    date = Date.parse( date );
    date = new Date( date );
    console.log( `date`, date )
  }
  let dayIndex = date.getDay();
  return daysOfWeek[ dayIndex ][ format ];
}

export function weekStartingToday() {
  let todayIndex = getDOW( 'i' );
  let wstArray = []
  let d = todayIndex;
  for ( let i = 0; i < 7; i++ ) {
    if ( d == 7 ) d = 0;
    let dayObj = new Object();
    dayObj[ i ] = d;
    if ( i == 0 || i == 1 ) {
      i == 0 ? dayObj.label = "Today" : dayObj.label = "Tomorrow";
    } else {
      dayObj.label = daysOfWeek[ d ].day;
    }
    dayObj.day = daysOfWeek[ d ].day;
    dayObj.abr = daysOfWeek[ d ].abr;
    dayObj.rr = daysOfWeek[ d ].rr;
    wstArray.push( dayObj )
    d++;
  }
  // console.log(`wstArray`, wstArray)
  return wstArray;
}

export function timeStamp( date, includeTime, utc ) {
  // includeTime, boolean. true = include Thh:mm:ss
  let d;
  if ( !date ) {
    d = new Date()
  } else {
    d = new Date( date )
  }
  // let now = new Date();
  // let utc = now.toISOString();
  let year = d.getFullYear();
  let month = String( d.getMonth() + 1 ).padStart( 2, "0" );
  let day = String( d.getDate() ).padStart( 2, "0" );
  let ts = `${year}-${month}-${day}`;
  let hours = String( d.getHours() ).padStart( 2, "0" );
  let minutes = String( d.getMinutes() ).padStart( 2, "0" );
  let seconds = String( d.getSeconds() ).padStart( 2, "0" );
  if ( includeTime ) {
    ts = `${ts}T${hours}:${minutes}:${seconds}`
  }
  if ( utc ) {
    let utcTS = new Date( ts ).toISOString().slice( 0, -5 ) + `+00:00`;
    return utcTS
  }
  return ts;
}

export function milTimeToday( milString ) {
  if ( milString == 0 ) milString = `0000`
  let mil = milString.toString();
  mil = mil.replace( ':', '' )
  let hours = mil.slice( 0, ( mil.length - 2 ) )
  let minutes = mil.slice( -2 )
  hours = parseInt( hours )
  minutes = parseInt( minutes )
  // hours = Math.floor( mil / 60 )
  // minutes = Math.floor( mil % 60 )
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let todayTime = new Date( year, month, day, hours, minutes, 0, 0 )
  // console.log( `og:`, milString, `hours:`, hours, `minutes`, minutes )
  // console.log( `todayTime`, todayTime )
  return todayTime
}

export function timeToday( ogTime ) {
  let time = new Date( ogTime )
  let hours = time.getHours();
  let minutes = time.getMinutes();

  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let todayTime = new Date( year, month, day, hours, minutes, 0, 0 )
  // console.log(`dateString`, dateString)
  let parsed = todayTime.getTime()
  // console.log(`parsed`, parsed)
  // let exactDateTime = new Date()
  // let dateOnly = Date.parse(`${exactDateTime.toDateString()}`)
  // let diff = exactDateTime - dateOnly
  // if (isNaN(parsed)) {
  //   console.log(`NaN:`, ogTime)
  // }
  // console.log(`parsed`, parsed)
  return parsed
  // return new Date(t);
}
// console.log(timeToday(`17:00:00+00:00`))
// console.log(timeStamp('2020-11-20T15:00:00-07:00',true,true))

export function getCustomUTC( date ) {
  let d
  if ( !date ) {
    d = new Date();
  } else {
    d = new Date( date )
  }
  d = d.toISOString();
  let custom = d.slice( 0, -5 ) + `+00:00`;
  return custom
}

export function getUTCTimeOnly( date ) {
  let utc = getCustomUTC( date )
  let timeOnly = utc.split( 'T' )[ 1 ]
  return timeOnly
}
// console.log(`customUTC:`,getCustomUTC(`2020-10-10T10:00`))

export function getStartAndEnd( date ) {
  let start = new Date( date );
  start.setHours( 0, 0, 0 );
  start = getMS( start );
  let end = new Date( date );
  end.setHours( 23, 59, 59 );
  end = getMS( end );
  let startEndObj = { start: start, end: end }
  // console.log(startEndObj);
  return startEndObj;
}

export function getDateOnly( date ) {
  var d = new Date( date ),
    month = '' + ( d.getMonth() + 1 ),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if ( month.length < 2 )
    month = '0' + month;
  if ( day.length < 2 )
    day = '0' + day;
  return [ year, month, day ].join( '-' )
}

export function getMS( date ) {
  let d = new Date( date );
  return d.getTime();
}

export function getSecondsSinceEpoch( date ) {
  let d;
  if ( !date ) {
    d = new Date()
  } else {
    d = new Date( date )
  }
  let seconds = Math.floor( d / 1000 )
  // console.log(`seconds:`, seconds)
  let newDate = new Date( seconds * 1000 )
  // console.log(`seconds String:`, newDate)
  return seconds
}

export function getAMSecondsSinceEpoch( date ) {
  let d;
  if ( !date ) {
    d = getDateOnly( new Date() );
  } else {
    d = getDateOnly( date )
  }
  return getSecondsSinceEpoch( d )
}

export function getYesterday( date ) {
  let d = new Date( date )
  let timeDiff = 1000 * 60 * 60 * 24
  let lessMS = ( d.getTime() - timeDiff ) / 1000;
  return lessMS
}
export function aWeekAgo( date ) {
  let d = new Date( date )
  let timeToAdd = 1000 * 60 * 60 * 24 * 7;
  return d.getTime() - timeToAdd
}

export function endDate( date ) {
  let d = new Date( date )
  let timeToAdd = 1000 * 60 * 60 * 24;
  return d.getTime() + timeToAdd
}

export function convertMilToLocal( int, narrow ) {
  // narrow: bool if want to slim down the numbers;
  if ( !int || int <= 0 ) return 0;
  let am = narrow ? 'a' : 'am'
  let pm = narrow ? 'p' : 'pm'
  let timeOfDay = int < 1200 ? am : pm
  if ( int >= 1300 ) int = int - 1200
  let string = int.toString();
  let hours = string.slice( 0, ( string.length - 2 ) )
  let minutes = string.slice( -2 )
  let minutesInt = parseInt( minutes )
  let minutesString = `:${minutes}`
  if ( narrow && !minutesInt ) minutesString = ``
  let timeString = `${hours}${minutesString}${timeOfDay}`
  return timeString
}

export function isToday( dow ) {
  let todayIndex = getDOW( 'i' );
  if ( dow.length > 2 ) {
    dow = dow.slice( 0, 2 ).toUpperCase()
  }
  let selectedDayIndex = getDayIndex( 'rr', dow )
  if ( todayIndex == selectedDayIndex ) {
    return true
  } else {
    return false
  }
}