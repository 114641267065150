<script>
  import LogItem from "./LogItem.svelte"
  import { fly, slide, scale } from "svelte/transition"
  import { flip } from "svelte/animate"
  import { logs } from "../js/stores"
</script>

<div class="log-items">
  {#each $logs as log, index (log.id)}
    <div class="transition" in:scale out:scale animate:flip={{ duration: 300 }}>
      <LogItem {log} {index} />
    </div>
  {/each}
</div>

<style>
  .log-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* overflow: hidden;
    overflow-y: auto; */
  }
  .transition {
    width: 100%;
  }
</style>
