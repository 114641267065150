<script>
  // svelte-ignore
  export let location = ""
  let throwaway = location
  // COMPONENTS
  import { onMount, setContext } from "svelte"
  // COMPONENTS
  import LogFilters from "../components/LogFilters.svelte"
  import LogForm from "../components/LogForm.svelte"
  import LogList from "../components/LogList.svelte"
  import {
    entry,
    editing,
    editItem,
    logs,
    logsDisplay,
    filtered,
    sortDesc,
    currentSort,
    tags,
  } from "../js/stores"
  $: $logs, updateLog()
  let init = false

  function newId() {
    let random = Math.floor(Math.random() * Date.now()).toString()
    let padded = random.padStart(16, "SL-000000000000000000")
    return padded
  }

  function updateLog() {
    console.log(`$logs`, $logs)
    // console.log(`$logs`, $logs)
    if (init && Array.isArray($logs)) {
      localStorage.setItem("logs", JSON.stringify($logs))
      let settings = { sortDesc: $sortDesc, currentSort: $currentSort }
      localStorage.setItem("settings", JSON.stringify(settings))
      if ($logs.length == 0) $tags.length = 0
    }
    if (filtered) console.log(Array.isArray($logs), $logs.length)
  }

  function parseHashTags(string) {
    let regex = /(^|\s)(#[a-z\d-]+)/gi
    let array = [...string.matchAll(regex)]
    if (array.length > 0) {
      let tags = []
      array.forEach((item) => {
        // console.log(item[2])
        tags.push(item[2])
      })
      // console.log(hashtags)
      return tags
      // console.log(log)
    }
  }

  function newEntryBase() {
    let now = Date.now()
    let entry = {
      id: newId(),
      tc: now,
      tm: now,
    }
    return entry
  }

  function copyLogEntry(id) {
    let og = $logs.filter((item) => item.id == id)[0]
    console.log(`og to copy:`, og)
    let copy = newEntryBase()
    copy.content = og.content
    copy.tags = parseHashTags(og.content)
    $logs = [...$logs, copy]
    sortLog($sortDesc, $currentSort)
    // setLocalStorage()
  }

  function addLogEntry(content) {
    if (content.length > 0) {
      let newLog = newEntryBase()
      newLog.content = content
      newLog.tags = parseHashTags(content)
      $logs = [...$logs, newLog]
      sortLog($sortDesc, $currentSort)
    }
  }

  function editLogEntry(content) {
    $logs = $logs.map((item) => {
      let tags = parseHashTags(content)
      let tm = Date.now()
      return item.id == $editItem.id
        ? { ...item, content, tm: tm, tags: tags }
        : { ...item }
    })
    $editItem = {}
    $editing = false
    sortLog($sortDesc, $currentSort)
  }

  function deleteLogEntry(id) {
    $logs = $logs.filter((item) => item.id != id)
    // setLocalStorage()
  }

  function prepEdit(id) {
    $editing = true
    // console.log(`editing?`, editing)
    $editItem = $logs.find((item) => item.id == id)
    $entry = $editItem.content
    // console.log(`editing?`, editing)
    // console.log($editItem)
  }

  // FUNCTIONS SORT
  function sortLog(direction, property) {
    // console.log(`direction`, direction, `property`, property)
    if (!property) property = "tc"
    if (!direction) direction = -1
    let n = direction * -1
    $logs = $logs.sort((a, b) => {
      return a[property] > b[property] ? n : -n
    })
  }

  // DEFAULT DATA
  const defaultData = [
    {
      id: newId(),
      tc: Date.now(),
      tm: Date.now(),
      content: "Your First #Log Entry...",
      tags: parseHashTags("Your First #Log Entry..."),
    },
  ]

  // CONTEXT
  const func = {
    add: addLogEntry,
    edit: editLogEntry,
    delete: deleteLogEntry,
    prepEdit: prepEdit,
    modify: prepEdit,
    sort: sortLog,
    copy: copyLogEntry,
  }

  setContext("func", func)

  function getSavedLog() {
    let local = localStorage.getItem("logs")
    // console.log(`local`, local)
    // console.log(`local parsed`, JSON.parse(local))
    if (local && JSON.parse(local).length > 0) {
      let parsed = JSON.parse(local)
      // console.log(`parsed`, parsed)
      $logs = parsed
      let settings = localStorage.getItem("settings")
      if (settings) {
        let parsed = JSON.parse(settings)
        $sortDesc = parsed.sortDesc
        $currentSort = parsed.currentSort
      }
      sortLog($sortDesc, $currentSort)
    } else {
      $logs = [...defaultData]
    }
    init = true
  }

  function displayLogs() {
    $logsDisplay = [...$logs]
  }

  onMount(() => {
    getSavedLog()
    displayLogs()
  })
</script>

<div class="wrapper">
  <LogForm />
  <LogFilters />
  <div class="scroll">
    <LogList />
  </div>
</div>

<style>
  .wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* justify-content: center; */
    align-items: center;
  }
  .scroll {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;

    /* margin-right: -10px; */
    /* padding-right: 20px; */
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--color_bg_1);
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: var(--color_bg_2);
    outline: 1px solid #000;
  }
</style>
