import { writable } from "svelte/store"
// import { daysOfWeek, getDayIndex } from "./time"

export let entry = writable("")

export let editItem = writable({})
export let editing = writable(false)

export let logs = writable([])
export let logsDisplay = writable([])
export let filtered = writable(false)

export let colorMode = writable("")

// SORT FILTERS
export let sortDesc = writable(true)
export let currentSort = writable("tc")
export let tags = writable([])

// export let selectedDOWabr = derived(
//   selectedDOW,
//   $selectedDOW => daysOfWeek[ getDayIndex( 'rr', $selectedDOW ) ].abr
// );
// export const time = readable(new Date(), function start(set) {
//   const interval = setInterval(() => {
//     set(new Date())
//   }, 1000)

//   return function stop() {
//     clearInterval(interval)
//   }
// })
