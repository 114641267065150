<script>
  // STORE
  import { entry, editing } from "../js/stores"
  let preview = { dislay: false, content: "" }
  $: $entry, updatePreview()

  // COMPONENTS
  import LogItem from "../components/LogItem.svelte"

  // CONTEXT
  import { getContext } from "svelte"
  const func = getContext("func")

  // VARIABLES
  // FUNCTIONS
  function updatePreview() {
    if ($entry) {
      preview.display = true
      preview.id = 0
      preview.tc = Date.now()
      preview.tm = Date.now()
      preview.content = $entry
    }
  }

  function handleSubmit() {
    // console.log(content)
    if ($editing) {
      func.edit($entry)
    } else {
      func.add($entry)
    }
    $entry = ""
  }
</script>

<form autocomplete="off" on:submit|preventDefault={handleSubmit}>
  <input
    name="entry"
    type="text"
    bind:value={$entry}
    placeholder="Add New Log Entry..." />
</form>
<!-- <div class="preview"> -->
<!-- {#if $entry} -->
<!-- <LogItem log={preview} index="0" preview="true" /> -->
<!-- <span class="preview-label">Log entry:</span>
    <span class="preview-entry">{$entry}</span> -->
<!-- {/if} -->

<!-- </div> -->
<style>
  form {
    width: 100%;
    margin-bottom: 15px;
  }
  form input {
    background-color: var(--color_bg_2);
    color: var(--color_primary);
    border: 0;
    padding: 10px;
    width: 100%;
  }
  /*
  .preview {
    margin: 20px;
    min-height: 20px;
    height: fit-content;
  }
  span.preview-label {
    font-style: italic;
    margin-right: 10px;
  }
  span.preview-entry {
    font-weight: bold;
  }
  */
</style>
