<script>
  // STORE
  import { sortDesc, currentSort, logs, tags } from "../js/stores"
  $: $logs, getUniqueTags($logs)
  $: $tags, console.log(`$tags`, $tags)

  import { getContext } from "svelte"
  const func = getContext("func")

  function toggleSort(property) {
    if ($currentSort == property) {
      $sortDesc = !$sortDesc
    } else {
      $currentSort = property
    }
    let direction = $sortDesc ? 1 : -1
    func.sort(direction, property)
  }

  function getUniqueTags(tagsArray) {
    if ($logs && $logs.length > 0) {
      let uniqueTags = []
      for (let i = 0; i < tagsArray.length; i++) {
        uniqueTags = [...new Set(uniqueTags.concat(tagsArray[i].tags))]
      }
      uniqueTags = uniqueTags.filter((item) => item != null)
      $tags = uniqueTags
      // console.log($tags)
    }
  }
  // console.log(`$logs unique tags`, getUniqueTags($logs))
</script>

<div class="filters">
  <!-- <div class="btn btn-sort" on:click={() => func.sort(1)}>Sort Descending</div> -->
  <!-- <div class="btn btn-sort" on:click={() => func.sort(-1)}>Sort Ascending</div> -->
  <div class="hash-filter">
    {#if $tags}
      {#each $tags as tag}<span class="tag">{tag}</span>{/each}
    {/if}
  </div>
  <div class="sort-toggles">
    <span
      class="btn toggle"
      class:active={$currentSort == `tc`}
      on:click={() => toggleSort(`tc`)}>
      Created
    </span>
    <span
      class="btn toggle"
      class:active={$currentSort == `tm`}
      on:click={() => toggleSort(`tm`)}>
      Modified
    </span>
    <span class="chevron btn" on:click={() => toggleSort($currentSort)}>
      {#if $sortDesc}
        <!--  -->
        <i class="fas fa-chevron-down" />
        <!--  -->
      {:else}
        <!--  -->
        <i class="fas fa-chevron-up" />
        <!--  -->
      {/if}
    </span>
  </div>
</div>

<style>
  .filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
  }
  .btn {
    margin-left: 15px;
    cursor: pointer;
  }
  .active,
  .chevron {
    color: var(--color_highlight);
  }
  .tag {
    margin-right: 8px;
    color: var(--color_highlight);
    cursor: pointer;
  }
</style>
